// extracted by mini-css-extract-plugin
export var topContentContainer = "desktop-index-module--topContentContainer--uUsru";
export var topDescription = "desktop-index-module--topDescription--dXAbc";
export var topTitle = "desktop-index-module--topTitle--7NyO3";
export var buttons = "desktop-index-module--buttons--0eQ22";
export var startNowButton = "desktop-index-module--startNowButton--VZ5Fi";
export var topButtonIcon = "desktop-index-module--topButtonIcon--U-pkA";
export var video = "desktop-index-module--video--uWhoe";
export var mainImage = "desktop-index-module--mainImage--5l+O4";
export var customersContentContainer = "desktop-index-module--customersContentContainer--9aM79";
export var customersTitle = "desktop-index-module--customersTitle--pLZBU";
export var customersDesc = "desktop-index-module--customersDesc--zOckh";
export var darkBg = "desktop-index-module--darkBg--NyeMV";
export var keyFeatureContainer = "desktop-index-module--keyFeatureContainer--8D1ml desktop-index-module--darkBg--NyeMV";
export var keyFeatureContentContainer = "desktop-index-module--keyFeatureContentContainer--8gryo";
export var KeyFeatureDescriptionContainer = "desktop-index-module--KeyFeatureDescriptionContainer--ykuTQ";
export var keyFeatureTitle = "desktop-index-module--keyFeatureTitle--vQKzW";
export var keyFeatureMenuContainer = "desktop-index-module--keyFeatureMenuContainer--VVnuk";
export var keyFeatureMenu = "desktop-index-module--keyFeatureMenu--Rbbtq";
export var selectedKeyFeatureMenu = "desktop-index-module--selectedKeyFeatureMenu--bfqGE";
export var keyFeatureSlide = "desktop-index-module--keyFeatureSlide--Hf-YN";
export var keyFeatureSlideLeft = "desktop-index-module--keyFeatureSlideLeft--WZScC";
export var startButtons = "desktop-index-module--startButtons--t9RVZ";
export var startButtonsDesc = "desktop-index-module--startButtonsDesc--Tscwu";
export var startButtonsTitle = "desktop-index-module--startButtonsTitle--cH-Rh";
export var sectorsContainer = "desktop-index-module--sectorsContainer--A3Djq desktop-index-module--darkBg--NyeMV";
export var sectorsContentContainer = "desktop-index-module--sectorsContentContainer--xsBj8";
export var sectorsTitle = "desktop-index-module--sectorsTitle--9-Vya";
export var sectorsDesc = "desktop-index-module--sectorsDesc--xEwAl";
export var sectorsCardContainer = "desktop-index-module--sectorsCardContainer--TxDsY";
export var sectorCard = "desktop-index-module--sectorCard--ZEbQX";
export var moreCard = "desktop-index-module--moreCard--2t67l";
export var featuresContainer = "desktop-index-module--featuresContainer--k71Cw";
export var featuresTitle = "desktop-index-module--featuresTitle--xapNK";
export var featureSubTitle = "desktop-index-module--featureSubTitle--n2wnS";
export var slideAndNavButtons = "desktop-index-module--slideAndNavButtons--ApYaj";
export var sliderWrapper = "desktop-index-module--sliderWrapper--Rzk7R";
export var slideNavButton = "desktop-index-module--slideNavButton--u5cl-";
export var slideDetailLink = "desktop-index-module--slideDetailLink--NsHLy";
export var slideDetailLinkContainer = "desktop-index-module--slideDetailLinkContainer--caZr6";
export var rightArrow = "desktop-index-module--rightArrow--SZHkB";
export var slideDetailDotGroup = "desktop-index-module--slideDetailDotGroup--mb7Zi";
export var slideDetailDot = "desktop-index-module--slideDetailDot--HJR1j";
export var slideDetailDotSelected = "desktop-index-module--slideDetailDotSelected--T1hdN";
export var partnersContainer = "desktop-index-module--partnersContainer--AjCSh";
export var partnersContentContainer = "desktop-index-module--partnersContentContainer--Oiw40";
export var partnersTitle = "desktop-index-module--partnersTitle--3Zwkp";
export var partner = "desktop-index-module--partner--5gsQw";