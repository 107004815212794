// extracted by mini-css-extract-plugin
export var darkBg = "mobile-index-module--darkBg--OksWg";
export var topContentContainer = "mobile-index-module--topContentContainer--50tfK";
export var topTitle = "mobile-index-module--topTitle--0olEX";
export var topDescription = "mobile-index-module--topDescription--iz0Ok";
export var consultingButton = "mobile-index-module--consultingButton--VUetO";
export var playerWrapper = "mobile-index-module--playerWrapper--hy73W";
export var player = "mobile-index-module--player--xRwVI";
export var customersContentContainer = "mobile-index-module--customersContentContainer--38I0J";
export var customersTitle = "mobile-index-module--customersTitle--CiBiQ";
export var customersDesc = "mobile-index-module--customersDesc--pvDiH";
export var keyFeatureContainer = "mobile-index-module--keyFeatureContainer--OgL3E mobile-index-module--darkBg--OksWg";
export var keyFeatureContentContainer = "mobile-index-module--keyFeatureContentContainer--jRbBY";
export var keyFeatureTitle = "mobile-index-module--keyFeatureTitle--dDPzm";
export var keyFeatureCarousel = "mobile-index-module--keyFeatureCarousel---bBej";
export var keyFeatureSlider = "mobile-index-module--keyFeatureSlider--gVfDR";
export var keyFeatureSelectorWrapper = "mobile-index-module--keyFeatureSelectorWrapper--qu7uU";
export var keyFeatureSelector = "mobile-index-module--keyFeatureSelector--Iv+ki";
export var slideNavButton = "mobile-index-module--slideNavButton--NwQCf";
export var keyFeatureDisplaySlide = "mobile-index-module--keyFeatureDisplaySlide--1s--E";
export var keyFeatureSlideTitleContainer = "mobile-index-module--keyFeatureSlideTitleContainer--sp2XC";
export var keyFeatureSlideTitle = "mobile-index-module--keyFeatureSlideTitle--IcFWN";
export var keyFeatureDotGroup = "mobile-index-module--keyFeatureDotGroup--CM0uH";
export var keyFeatureDot = "mobile-index-module--keyFeatureDot--Uvti6";
export var startButtons = "mobile-index-module--startButtons--VUEqJ";
export var startButtonsDesc = "mobile-index-module--startButtonsDesc--itZdP";
export var startButtonsTitle = "mobile-index-module--startButtonsTitle--p71yZ";
export var sectorsContainer = "mobile-index-module--sectorsContainer--lCSOa mobile-index-module--darkBg--OksWg";
export var sectorsContentContainer = "mobile-index-module--sectorsContentContainer--M4S6j";
export var sectorsTitle = "mobile-index-module--sectorsTitle--x7qNr";
export var sectorsDesc = "mobile-index-module--sectorsDesc--K5nuL";
export var sectorsCardContainer = "mobile-index-module--sectorsCardContainer--buRrd";
export var sectorsFadeOut = "mobile-index-module--sectorsFadeOut--KnZlv";
export var sectorCard = "mobile-index-module--sectorCard--Q4o+U";
export var featuresContainer = "mobile-index-module--featuresContainer--8csVd";
export var featuresTitle = "mobile-index-module--featuresTitle--lv5Z8";
export var slideDetailDotGroupContainer = "mobile-index-module--slideDetailDotGroupContainer--4bm9N";
export var slideDetailDotGroup = "mobile-index-module--slideDetailDotGroup--J18cu";
export var slideDetailDot = "mobile-index-module--slideDetailDot--Odd++";
export var slideDetailDotSelected = "mobile-index-module--slideDetailDotSelected--tgRZK";
export var slideDetailDotBackground = "mobile-index-module--slideDetailDotBackground--YOoJy";
export var featureSubTitle = "mobile-index-module--featureSubTitle--wWWBi";
export var featureSwiperWrapper = "mobile-index-module--featureSwiperWrapper--+e+9P";
export var featureSwiper = "mobile-index-module--featureSwiper--rK1BY";
export var slideDetailLink = "mobile-index-module--slideDetailLink--pcFCE";
export var slideDetailLinkContainer = "mobile-index-module--slideDetailLinkContainer--cAIN1";
export var rightArrow = "mobile-index-module--rightArrow--CfZs6";
export var partnersContainer = "mobile-index-module--partnersContainer--fPZhx";
export var partnersContentContainer = "mobile-index-module--partnersContentContainer--8+NpD";
export var partnersTitle = "mobile-index-module--partnersTitle--aQCNl";
export var partners = "mobile-index-module--partners--3qYDC";